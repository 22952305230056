export const m = 
{ 
menu:"首页",
menu0:"跨链桥",
menu1:"发射台",
menu2:"交易市场",
menu3:"流动性",
menu4:"工具集",
 PinkTitle:"Pink Bridge  基于Fractal Bitcoin的跨链桥",
desc:"Pink Bridge 是基于Pink Finance的 的资产跨链工具，专注于支持Fractal Bitcoin网络与其他区块链之间的NFT跨链转移。其提供了双向桥接功能，支持Fractal Bitcoin与 其他链之间的资产转移。",
title1:"Pink MysteryLand",
desc1:"Pink MysteryLand是由Pink Finance赋能的基于Fractal Bitcoin的首次发射平台。 其使命是成为 Fractal Bitcoin上的 Gitcoin，构建一个基本的捐赠协议，让更多的开源开发者和社区能够参与 Fractal Bitcoin 的生态建设并从中获益。",
title2:"Pink SWFT",
desc2:"Pink SWFT旨在打造基于Fractal Bitcoin的创新驱动型NFT聚合交易市场，为用户打造更好的交易体验。",
title3:"Pink Master",
desc3:"Pink Master是基于基于Fractal Bitcoin的NFT流动性解决方案。通过利用先进的跨链资产协议和债券曲线，我们创造了充足的NFT流动性，为NFT市场生态提供了更加透明和稳健的流动性解决方案。",
title4:"Pink NOVA",
desc4:"Pink NOVA将打造基于Fractal Bitcoin的NFT聚合工具集。其中包含灵活的跨链NFT工具，NFT发行工具及与之配套的AMM型 Swap等，为NFT提供价值超多的新功能（经济启动、链上版权费、租赁机制、时间/价值/事件锁定）、贬值保护和反欺诈系统，这些工具可以轻松地用在GameFi、交易市场、艺术品、元宇宙和非抵押类NFT租赁等领域。",



}