export const m = 
{
    lqlj:"확인하세요",
sqinfo:"개인 계정을 사용하려면 지갑 인증을 사용하십시오",
sqbtn:"지갑 인증",
sqload:"라이선스로...",
dltt:"계정에 로그인",
dlinfo:"개인 계정을 사용하려면 지갑 링크를 클릭하세요",
dlbtn:"링크 지갑",
bdtt:"상위 바인딩",
bdinfo:"추천인의 지갑 주소를 입력해주세요",
bdbtn:"바인딩 확인",
bdload:"바인딩 중...",
lqsg:"성공했습니다",
lqsb:"수신에 실패했습니다",
day:"하루",
shi:"시간",
fen:"나누다",
미오:"초\"입니다",
fzcg:"성공적으로 복제했습니다",
fzsb:"복제 실패",
yhdj:"사용자 레벨",

notice:"arc는 세계 최초의 지능형 컴퓨팅 통합 서비스 네트워크 구축을 기반으로 한 web3 지능형 컴퓨팅 금융 플랫폼이며 세계 최초의 무간섭, 자동화 운행을 실현한 금융 서비스 플랫폼이다.",
cyarc:"arc 참여",
ljyy:"바로 예약하세요",
yysl:"예약 수량",
xzsl:"개수 선택",
qr:"확인",
wdldx:"나의 유동성",
wdlp:"나의 lp 값",
wdqydd:"제 계약서의 주문서",
jrgtxq:"arc와 함께 defi의 새로운 물결을 일으키자!",
qyc:"계약 풀",
cbdz:"소지권 주소",
wdqy:"나의 계약",
qysy:"계약 수익",
qrlq:"수령 확인",
lq:"받아요",
mx:"상세한 자료",
dqjl:"커뮤니티 보상",
ny:"있잖아요",
wcqbqr:"지갑 확인 완료",
zyyyyd:"주의사항:24시간내에 한건의 주문만 예약할수 있다",
dqsl:"공인 수량",
yycg:"예약 성공",
yysb:"예약 실패",
mysj:"데이터가 없어",
sqjd:"커뮤니티 노드",
gsjd:"컨센서스 노드",
dqjx:"커뮤니티 캠페인",
gsjdzsl:"컨센서스 노드의 총 수",
gsjdydcsl:"컨센서스 노드의 합의된 개수",
dqgsjdjc:"현재 컨센서스 노드 프라이즈 풀",
wdjdjc:"내 노드 보너스 풀",
wdcjd:"실패한 노드 입니다",
ydcjd:"완료된 노드",
djzlmm:"총 노드 51개, 섹션 실적이 2w 달러에 도달하면 순서대로 노드 된다.",
jdmx:"노드 정보",
xh:"일련번호",
dz:"주소요",
sqjxjc:"커뮤니티 캠페인 풀",
wdsqjxjc:"내 커뮤니티 캠페인 풀",
xqyjmm:"소구역 업적이 15,000달러에 달했고, 상위 51위까지는 선거풀 상금을 받게 됩니다.",
sqpm:"커뮤니티 순위",
pm:"순위",
xqyj:"커뮤니티 업적",
zyj:"전체 업적입니다",
dq:"커뮤니티",
wdyqlj:"내 초대장 링크",
fz:"복제",
qysqtj:"계약 커뮤니티 통계",
zjtjhy:"직접 회원을 추천합니다",
xqyjzje:"소구역 실적 총금액",
sqyjzje:"총 커뮤니티 업적",
wdfxmx:"내 공유 명세서",
wx:"유효하지 않습니다",
위dyydd:"나의 예약주문",
ywcdqydd:"완료된 계약 주문서",
dzf:"지불을 기다린다",
yyz:"예약중입니다",
dyz:"저당잡히기에요",
qyje:"계약된 금액",
yydjs:"카운트다운 예약",
zydjs:"저당 잡힌 카운트다운",
zfdjs:"지불 카운트다운",
wcqydd:"계약 주문을 완료하세요",
ywc:"완료",
ysh:"지불했습니다",
zysl:"담보의 수량",
jydjs:"압송 카운트다운",
shqydd:"상환 계약 주문서",
wcqyddxyzf:"예약주문 완료시 지불이 필요합니다",
shcg:"성공적인 보상",
shsb:"상환 실패입니다",
zfcg:"결제 성공",
zfsb:"지불 실패",
wdfx:"나의 공유",
yxyh:"유효한 사용자",
wxyh:"잘못된 사용자",
grtr:"개인적투자",
sqyj:"커뮤니티 업적",
sfjd:"노드 여부",
shi:"그렇다",
fou:"아니요",
home:"홈",
wdsq:"나의 커뮤니티",
jdjl:"노드 보상",
jxjl:"선거 상금이요",
tx:"현금 인출",
jl:"보상 이요",
sl:"수량",
swjryy:"예약에 가입하지 않아 친구를 공유할 수 없습니다",
wdtjdz:"내 추천 주소",
qqdd:"지갑 상호 작용 요청",
dkndqb:"주문을 계속하려면 지갑을 열고 거래 활동에서 확인하십시오.",
zzclsw:"ARC는 블록체인 거래를 하고 있으니 거래 결제를 기다려 주십시오",
ddz:"대기 중",
dqzdbmz:"현재 주소가 초대 조건을 충족하지 않습니다!",
bklq:"수령불가!",

dappname:"다롄바오",
wdzc:"나의 자산",
cz:"충전",
czjl:"충전 기록",
tx:"현금 인출",
txjl:"현금인출기록",
hdqy:"활동 지역",
djkpsp:"당국 카드 조각",
zhang:"장",
dqxhz:"영역파괴 값",
ljjcze:"총 상금 풀",
qyfhze:"이익배당금 총액",
jrqy:"진입 지역",
czqr:"충전확인",
srsl:"숫자를 입력하십시오",
txqr:"현금인출확인이다",
czcg:"성공적으로 충전했습니다",
czsb:"충전 실패",
txzwkf:"현금인출은 아직 개방되지 않았습니다",
txcg:"성공적인 현금 인출",
txsb:"현금 인출 실패",
yxxq:"게임 정보",
jryx:"게임에 들어가세요",
ljcy:"즉시 참여하세요",
lun:"바퀴입니다",
dqrs:"현재 인원수",
jtiz:"진행 중입니다",
yjs:"끝났습니다",
cyyx:"게임에 참여하기",
wdye:"내 나머지 사람들",
cycg:"성공적으로 참여했습니다",
xh:"일련번호",
dz:"주소요",
hd:"가져오기",
연사:"비율",
qykp:"권리 카드",
kpsl:"카드 총량",
yxkp:"유효한 카드",
wxkp:"유효하지 않은 카드",
qyjlljff:"혜택의 누적 지급",
zcmx:"자산 목록",
sr:"수입 입니다",
zc:"지출",
lx:"타입이라고요",
yxzj:"게임에 당첨되었네요",
qyfh:"이익 분배",
bz:"설명이요",
디디:"디",
gglb:"발표 목록",
ggxq:"자세한 내용을 공개합니다.",
bh:"각하",
dsh:"심의를 기다리다",
qrz:"확인 중"
}