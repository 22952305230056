<template>
<div :class="show ? 'h5' : ''">
  <div class="mtop"  v-if="!show">
    <div class="logo">
      <img src="../assets/img/logo.png" alt=""  class="img2" />
    </div>
    <div class="right">
    <div class="menu">
      <div class="mitem" :class="active=='/'?'acts':''" @click="gbsmenu('/')">
        {{$t('m.menu')}}
      </div>
      <div class="mitem " :class="active=='/klq'?'acts':''" @click="gbsmenu('/klq')">
        {{$t('m.menu0')}}
      </div>
      <div class="mitem" :class="active=='/fst'?'acts':''" @click="gbsmenu('/fst')">
        {{$t('m.menu1')}}
      </div>
      <div class="mitem" :class="active=='/jysc'?'acts':''" @click="gbsmenu('/jysc')">
        {{$t('m.menu2')}}
      </div>
      <div class="mitem" :class="active=='/ldx'?'acts':''" @click="gbsmenu('/ldx')">
        {{$t('m.menu3')}}
      </div>
      <div class="mitem" :class="active=='/gjj'?'acts':''" @click="gbsmenu('/gjj')">
        {{$t('m.menu4')}}
      </div>
    </div>
    <div class="lang" @click="onSelect">
      {{dqyy=='en-US'?'EN':"CN"}}
    </div>
    <div class="navbtn fadeInRight" @click="ethConnect">
          <span>{{userWallet?userWallet :'Connect' |yingcang}}</span>
      </div>
  </div>
  </div>
    <div class="top" v-if="show">        
        <div class="logo fadeInLeft">   
       <div class="slogo " > 
         <div class="slo">
         <img src="../assets/img/logo.png" alt=""  class="img2" />
         </div>
         </div>       
        </div>
    
        <div class="flexright">
          <div class="lang" @click="onSelect">
          {{dqyy=='en-US'?'EN':"CN"}}
        </div>
          <div class="navbtn fadeInRight" @click="ethConnect">
          <span>{{userWallet?userWallet :'Connect' |yingcang}}</span>
        </div>
          <div class="icosn">
       <img src="../assets/img/menu.png" alt="" class="img" @click="menuopen" />      
       </div></div>
    </div>

      <!-- 菜单 -->
    <van-popup
      v-model="clmenuShows"
      position="right"
      :style="{ height: '100%', 
      width:'60%',
      background: '#ffffff'}"
    >
    <div class="sfelx" >
      <div class="mentop">
        <div class="slogs">
           <img src="../assets/img/logo.png" alt="" class="logo" />
        </div>
        <!-- <img src="../assets/img/gbn.png" alt="" class="close" @click="gbsmenu()"/> -->
      </div>
     
         <div class="menulist">
          <div class="menuitem" :class="active=='/'?'act':''" @click="gbsmenu('/')">
          <div class="left">
            <div class="txt"> {{$t('m.menu')}}</div>
          </div>  
          <div class="right">
          </div>           
        </div>
        <div class="menuitem" :class="active=='/klq'?'act':''" @click="gbsmenu('/klq')">
          <div class="left">
            <div class="txt"> {{$t('m.menu0')}}</div>
          </div>  
          <div class="right">
          </div>           
        </div>
        <div class="menuitem " :class="active=='/fst'?'act':''" @click="gbsmenu('/fst')">
          <div class="left">
            <div class="txt"> {{$t('m.menu1')}}</div>
          </div>   
          <div class="right">
          </div>      
        </div>
        <div class="menuitem " :class="active=='/jysc'?'act':''" @click="gbsmenu('/jysc')">
          <div class="left">
            <div class="txt"> {{$t('m.menu2')}}</div>
          </div>   
          <div class="right">
          </div>      
        </div>
        <div class="menuitem " :class="active=='/ldx'?'act':''" @click="gbsmenu('/ldx')">
          <div class="left">
            <div class="txt"> {{$t('m.menu3')}}</div>
          </div>   
          <div class="right">
          </div>      
        </div>
        <div class="menuitem " :class="active=='/gjj'?'act':''" @click="gbsmenu('/gjj')">
          <div class="left">
            <div class="txt"> {{$t('m.menu4')}}</div>
          </div>   
          <div class="right">
          </div>      
        </div>
      </div>
      <div class="foot">     

      </div>
</div> 
    </van-popup>
   
</div>
</template>

<script>
import { connectTron} from "@/api/Public";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },
  },
  data(){
    return{
      userWallet:'',
      clmenuShows:false,
      show:false,
      active:"/",
      lang:{},
      langs:[]
    }
  },
  filters:{
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{7})\w+(\w{4})/, "$1...$2");       
        return a;
      } else {
        return "...";
      }
    },
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    }
  },
  created(){
    // this.ethConnect()
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
      // this.wallet()
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    ethConnect() {
      connectTron().then((info) => {
        if (info[0].indexOf("0x") == 0) {  
          this.userWallet=info[0]  
          this.$store.commit("userWallet", info[0]);    
        } else {
          this.$toast(this.$i18n.t("m.qsydzdl"));
        }
      });
    },
    onSelect(item) {
      let val=this.dqyy=='en-US'?'zh-CN':'en-US'
      this.$i18n.locale = val;
      this.$store.commit("language", val);
    },
    menuopen() {
      this.langs.map(el=>{
      if(el.value==this.$store.getters.language){
        this.lang=el
      }
    })
    this.active=this.$route.path;
      this.clmenuShows = true;
    },
    gbsmenu(e) {
      // if (e == this.active) {
      //   return;
      // }
      this.active=e
      this.clmenuShows = false;      
      if(e){
      // this.handleFiexdToolItem(e)
      }
    },
    handleFiexdToolItem(e){
    // this.$notify(this.$i18n.t("m.wxtszwkf"))
    // return
  this.$router.push({path:e})
},
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.mtop{
  display: flex;
  align-items: center;  
  max-width: 1440px;
      width: 77%;
  justify-content: space-between;
  margin: 0 auto;
  height: 107px;
  background: transparent;
  .logo{
    .img2{
      width:94px;
      height: auto;
    }
  }
  .right{
    display: flex;
  
  .menu{
    display: flex;
    align-items: center;  
    justify-content: space-between;
    .mitem{
      font-weight: bold;
      font-size: 18px;
      color: #353945;
      // min-width: 90px;
      margin: 0 20px;
      cursor: pointer;
    }
    .mitem::after{
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #F95C99;
    }
    .acts,.mitem:hover{
      color: #F95C99;  
      position: relative;    
    }
  }
  .lang{
      margin-left: 71px;
      margin-right: 16px;
      font-size: 18px;
      font-weight: bold;
      color: #353945;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  .lang:hover{
    color: #F95C99;
  }
  .navbtn {
      display: flex;
      justify-content: center;
      align-items: center;
     min-width: 170px;
     height: 42px;
border-radius: 100px;
margin-right: 16px;
background-size: 100% 100%;
font-family: $t-mf;
background: #FDEAF1;
font-weight: bold;
font-size: 16px;
line-height: 24px;
color: #F95C99;
  cursor: pointer;
    }
  }
}
.top {
    // height: 156px;
    // @include background_color("background_color1");
    
    position: fixed;
    top: 0;
    z-index: 99;    
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 15px;
    width: calc(100%);
    max-width: 750px;
    margin: 0 auto;
    color: #fff;
    z-index: 9999;
    box-sizing: border-box;
    .logo {
      display: flex;
      align-items: center;    
      .slogo{
display: flex;
align-items: center;
      .slo{
// background: linear-gradient(
// 128deg
// ,#ffdf2d,#00e2be);
// background: linear-gradient(
// 128deg
// ,#e7efcd,#00e2be);
// box-shadow: 0px 4px 16px 4px #00e2be;
// border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
      }

.ttt{
font-size: 32px;
font-family:$t-f;
font-weight: 600;
color: #FFFFFF;
margin-left: 10px;
}
        .img2{
          width: auto;
          height: 48px;
          margin-left: 10px;
          // border-radius: 6px;
        }
      }
    }
     .title{
      color: #fff;
      font-size: 36px;
      font-family: $t-mf;
    }
    .lang{
      margin-right: 21px;
      font-size: 18px;
      font-weight: bold;
      color: #F95C99;
      cursor: pointer;
    }
    .lang:hover{
      color: #F95C99;
    }
    .navbtn {
      display: flex;
      justify-content: center;
      align-items: center;
     min-width: 170px;
     padding: 0 40px;
height: 60px;
border-radius: 100px;
margin-right: 16px;
background-size: 100% 100%;
font-family: $t-mf;
background: #FDEAF1;
font-size: 28px;
font-weight: 600;
color: #F95C99;
  
    }
      .icosn {
        margin-right: 15px;
       width: 42px;
height: 42px;
// border-radius: 16px;
display: flex;
align-items: center;
justify-content: center;
        .img{
          width: 42px;
        }
      }
      .flexright{
        display: flex;
        align-items: center;      
      }
  }

  .sfelx{
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: 1px solid #F95C99;
  }

  .mentop {
    display: flex;
    align-items: center;
    margin: 44px 30px;
    position: relative;
    
justify-content: center;
    .slogs{
      //  width: 56px;
// height: 56px;
margin: 44px 30px;
// background: linear-gradient(
// 128deg
// ,#e7efcd,#00e2be);
display: flex;
align-items: center;
justify-content: center;
border-radius: 100%;
   .logo {
      height: 128px;
    }
  
    }
    .close{
      position: absolute;
      right: 0;
      top: 0px;
      width: 56px;
      height: 56px;
    }
    .gb {
      font-size: 32px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 44px;
margin-left: 12px;
    }
  }
  .menulist {
    margin-top: 30px;
    .menuitem {
      display: flex;
      align-items: center;      
    justify-content: space-between;
      margin: 20px 24px 20px 44px;
      padding: 16px;
      // border: 1px solid #F95C99;
    border-radius: 10px;
      .left {
        margin-right: 12px;
        text-align: left;
        display: flex;
        img {
          height: 44px;
          width: 44px;
        }
        .txt {
          margin-left: 30px;
          font-family: $t-mf;
          // font-weight: 600;
          font-size: 28px;
          color: #F95C99;
          opacity: 0.8;
          line-height: 50px;
        }
      }
      .right {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        img{
          width: 26px;
          height: 26px;
        }
      }
    }
    .act{
       .txt {
         opacity: 1 !important;
         font-weight: bold;
       }
    }
  }
  .foot{
        display: flex;
    flex-direction: column;
    flex: 1;
    // justify-content: center;
    padding: 40px;
    .lang{
      display: flex;
      align-items: center;      
    justify-content: center;
    border: 1px solid #735f24;
    width: 240px;
    height: 68px;
    border-radius: 58px;
    margin: 10px auto 40px;
    .bbox{
      display: flex;
      align-items: center;      
    justify-content: center;
    }
      .limg{
        height: 40px;
        width: 40px;
        border-radius: 100%;
      }
      .ttl{
        font-size: 24px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 34px;
margin-left: 10px;
margin-right: 10px;
      }
      .dwimg{
        width: 32px;
        margin-left: 10px;
      }
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;
      color: #ffffff;
          background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
    }
    .price{
      font-size: 24px;
font-family:$t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 34px;
margin: 18px 0;
text-align: left;
.num{
  margin-left: 20px;
  font-family: $t-mf;
}
    }
    .imgline{
      display: flex;
      align-items: center;
    }
    .slog{
      height: 44px;
      width: 44px;
      margin: 20px;
    }
  }
  .purp{
    position: absolute;
    left: 50%;
    border: 1px solid #20202080;
    transform: translateX(-50%);
    top: 122px;
    background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
    padding: 15px 20px;
    inset: 0px auto auto 50%;
    transform: translate3d(-50%, 800px, 0px);
    z-index: 9999;
    .linex{
      display: flex;
      align-items: center;
      margin: 16px;
      .limg{
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }      
      .txt{
        margin-left: 20px;
        font-size: 24px;
        color: #FFFFFF;
      }
    }
  }
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
