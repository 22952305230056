<template>
  <div class="home" :class="show ? 'h5' : ''">
  <Header/>
  <div class="bgbox">
      <img src="../assets/img/bgx.png" alt=""  class="bgx" />
      <img src="../assets/img/bgx1.png" alt=""  class="bgx1" />
     </div>
 <div class="content">  
  <div class="boxtop">
    <div class="left">
      <div class="title">{{$t('m.PinkTitle')}}</div>
      <div class="desc">{{$t('m.desc')}}</div>
      <div class="btn">Buy</div>
    </div>
    <div class="right">
    <div class="banner">
        <div class="posrt">
          <div class="sl"  @click="props"><img src="../assets/img/sl.png" alt=""  class="ico" /></div>
          <div class="sr"  @click="toAdd"><img src="../assets/img/sr.png" alt=""  class="ico" /></div>
        </div>
          <div class="banbox">
            <img :src="banner[cur==0?6:cur-1].thumb" alt="" class="imgs1" :class="act?'fadeInLeft':''"/>
            <img :src="banner[cur].thumb" alt="" class="imgss fadeIn" :class="act?'fadeInUp':'fadeUpDown'"/>
           <img :src="banner[cur+1==6?0:cur+1].thumb" alt="" class="imgs2 " :class="act?'fadeInRight':''"/>
          </div>  
          <div class="info">
            <div class="slt">
              <div class="name">Space shipping</div>
              <div class="price">4.10 ETH</div>
            </div>
            <div class="slt">
            <div class="nir">
              <div class="icos"></div>
              <div class="txt">Tran Mau Tri Tam</div>
            </div>
            <div class="floor">
              Floor 0.03ETH
            </div>
          </div>
          </div>  
    </div>
  </div>
  </div>

  <div class="cbox">
    <div class="dian"></div>
    <div class="dian2"></div>
    <div class="dian3"></div>
      <div class="sinfo fxm">
        <div class="imgx">
          <img src="../assets/img/img1.png" alt=""  class="img" />
        </div>
        <div class="txtbox">
          <div class="title">{{$t('m.title1')}}</div>
          <div class="desc">{{$t('m.desc1')}}</div>
        </div>
      </div>

      <div class="sinfo">
        
        <div class="txtbox">
          <div class="title">{{$t('m.title2')}}</div>
          <div class="desc">{{$t('m.desc2')}}</div>
        </div>
        <div class="imgx">
          <img src="../assets/img/img2.png" alt=""  class="img" />
        </div>
      </div>

      <div class="sinfo fxm">
        <div class="imgx">
          <img src="../assets/img/img3.png" alt=""  class="img" />
        </div>
        <div class="txtbox">
          <div class="title">{{$t('m.title3')}} </div>
          <div class="desc">{{$t('m.desc3')}}</div>
        </div>
      </div>

      <div class="sinfo">
      
        <div class="txtbox">
          <div class="title">{{$t('m.title4')}}</div>
          <div class="desc">{{$t('m.desc4')}}</div>
        </div>
        <div class="imgx">
          <img src="../assets/img/img4.png" alt=""  class="img" />
        </div>
      </div>
  </div>


 </div>    
    
<Bottom/>
 
  </div>
</template>

<script>
import { connectTron} from "@/api/Public";
import Header from '../components/header.vue';
import Bottom from '../components/bottom.vue';
export default {
  name: "Home",
  components: {Header,Bottom},
  data() {
    return {
      cur:1,
      show:false,
      act:false,
      banner: [ 
        {  "thumb": require('../assets/img/banner1.png'),"thumb_en": require('../assets/img/banner1.png')},
        {  "thumb": require('../assets/img/banner2.png'),"thumb_en": require('../assets/img/banner2.png')},
        {  "thumb": require('../assets/img/banner3.png'),"thumb_en": require('../assets/img/banner3.png')},
        {  "thumb": require('../assets/img/banner4.png'),"thumb_en": require('../assets/img/banner4.png')},
        {  "thumb": require('../assets/img/banner5.png'),"thumb_en": require('../assets/img/banner5.png')},
        {  "thumb": require('../assets/img/banner6.png'),"thumb_en": require('../assets/img/banner6.png')},
        {  "thumb": require('../assets/img/banner7.png'),"thumb_en": require('../assets/img/banner7.png')},
      ],   



    };
  },
  filters: {
      Endtime(e){
    var    end_str = (e).replace(/-/g,"/");//一般得到的时间的格式都是：yyyy-MM-dd hh24:mi:ss，所以我就用了这个做例子，是/的格式，就不用replace了。  
    var end_date =new Date(end_str)-new Date;//将字符串转化为时间  
    return end_date
    },
   
    formatDate(e) {
      if (e == 0) {
        return "";
      } else {
        let date = new Date(e * 1000);
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let D = date.getDate();
        let h = date.getHours();
        let m = date.getMinutes();
        let s = date.getSeconds();
        if (M < 10) {
          M = "0" + M;
        }
        if (D < 10) {
          D = "0" + D;
        }
        if (h < 10) {
          h = "0" + h;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }
        return `${Y}-${M}-${D} ${h}:${m}:${s}`;
      }
    },
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{7})\w+(\w{4})/, "$1...$2");       
        return a;
      } else {
        return "...";
      }
    },
      time(time){
      if(time){
        var reg = /^(\d{4})-(\d{1,2})-(\d{1,2})\s(\d{1,2})(:(\d{1,2})){2}$/;
        var end = time.match(reg).slice(1,4).join('-');
        return end
      }
    }
  },
   watch: {
    "$store.getters.userWallet": function (val) {
      //  this.pustadd(val)
    }, 
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
      // this.wallet()
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  created() {  

  },

  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },  
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onChange(index) {
      this.cur = index;
    },
    props(){
      this.act=true
      if(this.cur==0){
        this.cur=6
      }
      this.cur=this.cur-1
      setTimeout(() => {
        this.act=false
        this.$forceUpdate()
      }, 1000);
    },
    toAdd(){
      this.act=true
      this.cur+=1
      if(this.cur==6){
        this.cur=0
      }
      setTimeout(() => {
        this.act=false
        this.$forceUpdate()
      }, 1000);
      
     
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #FCFCFD;
  overflow-x: hidden;
  margin: 0 auto;
  min-height: 100vh;
  .bgbox{
    position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .bgx{
      width: 537px;
      height: auto;
      position: absolute;
      left: 0;
      top: -100px;
      }
      .bgx1{
        width: 437px;
        height: auto;
        position: absolute;
      left: 0;
      top: 207px;
      }
  }
  .content{
    height: 100%;
    position: relative;
    .boxtop{
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 676px;
      max-width: 1440px;
      width: 77%;
      margin: 0 auto;
      .left{
        margin-right: 64px;
        .title{
          font-size: 64px;
          color: #23262F;
          font-weight: 400;
          line-height: 64px;
          min-width: 550px;
        }
        .desc{
          width: 450px;
          font-size: 16px;
          line-height:24px;
          font-weight: 400;
          color: #353945;
          text-align: left;
          margin: 31px 0;
        }
        .btn{
          background: #FDEAF1;
          width: 154px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #F95C99;
          font-weight: bold;
          border-radius: 22px;
          cursor: pointer;
        }
      }
      .right{
        .banner{
          position: relative;
          .my-swipe1{
            width: 711px;
          }        
          .posrt{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 551px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 999;
           .sr, .sl{
              width: 54px;
              height: 54px;
              background: rgba(255,255,255);
              box-shadow: 1px 2px 5px #535353cc;
              border-radius: 50%;
              display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
              // filter: blur(10px);
            }
            .sr:hover,.sl:hover{
              box-shadow: 1px 2px 5px rgba(212,215,224,0.8);
            }
            .ico{
              width: 18px;
            }
          }
          .banbox{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 711px;
            position: relative;
            .imgs1{
              height: 322px;
              position: absolute;
              left: 0;
              top: 43px;
            }
            .imgs2{
              height: 322px;
              position: absolute;
              right: 0;
              top: 43px;
            }
            .imgss{
                width: 551px;
                position: relative;
                z-index: 99;
              }
          }
          .info{
            width: 311px;
            height: 84px;
            box-shadow: 0px 40px 32px rgba(15,15,15,0.12);
            border-radius: 16px;
            background: #ffffff;
            display: flex;
              align-items: center;
              flex-direction: column;
              justify-content: center;
              padding: 13px;
              box-sizing: border-box;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
              z-index: 999;
            .slt{
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              .name{
                color: #23262F;
                font-size: 18px;
                line-height: 29px;   
                font-weight: bold;             
              }
              .price{
                font-size: 16px;
                color: #23262F;
                line-height: 24px;
                font-weight: bold;  
              }
              .nir{
                display: flex;
              align-items: center;
              .icos{
                min-width: 19px;
                min-height: 19px;
                border-radius: 100%;
                background: #F95C99;
                margin-right: 8px;
              }
              .txt{
                font-size: 12px;
                line-height: 20px;
                color: #353945;
              }

              }
              .floor{
                color: #777E90;
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
         
        }
      }

      
    }
    .cbox{
      background: #F8F8F9;
      width: 100%;
      position: relative;
      .dian{
        background: linear-gradient(90deg, #C9FFF5 0%,#C9FFF5 40%,  #FFE5B4 100%);
        width: 266px;
        height: 470px;
        position: absolute;
        right: 0px;
        z-index: 0;
        border-radius: 100%;
        filter: blur(40px);
        top: -160px;
        opacity: 0.3;
      }
      .dian2{
        background: #FFA4E0;
        width: 266px;
        height: 470px;
        position: absolute;
        left: -50px;
        z-index: 0;
        filter: blur(80px);
        top: 160px;
        opacity: 0.3;
      }
      .dian3{
        background: radial-gradient(circle, #FFADA9 0%, #CAEDFD 47%, #E6D0F5 84%, #E6CFF4 100%);
        width: 492px;
        height: 766px;
        position: absolute;
        left: -50px;
        z-index: 0;
        filter: blur(60px);
        bottom: 10%;
        opacity: 0.4;
      }
      .sinfo{        
        max-width: 1440px;
      width: 77%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 10;
        .imgx{
          width:460px ;
          height: 460px;
          .img{
            width: 100%;
            height: 100%;
          }
        }
        .txtbox{
          width: 530px;
          .title{
           color: #F95C99;
           font-size: 40px;
           line-height: 44px; 
          }
          .desc{
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            margin-top: 21px;
          }
        }
      }
    }
  }

}
.h5{
  .bgbox{
    position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .bgx{
      width: 687px;
      height: auto;
      position: absolute;
      left: 0;
      top: -200px;
      }
      .bgx1{
        width: 737px;
        height: auto;
        position: absolute;
      left: 0;
      top: 247px;
      }
  }
  .content{

    .boxtop{
      flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 110px;
    .left{
      margin:52px 0 ;
      .title{
        font-size: 96px;
        line-height: 112px;
        width: 686px;
        margin: 0 auto;
        text-align: center;
      }
      .desc{
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        width: 80%;
        margin: 50px auto;
      }
      .btn{
        margin: 80px auto 0;
        border-radius: 50px;
        width: 308px;
        height: 96px;
        font-size: 32px;
        line-height: 48px;
      }
    }
    .right{
      margin-bottom: 160px;
    }
    }
    .cbox{
      margin: 0 auto 71px;
      .dian{
        background: #FFE5B4;
        width: 266px;
        height: 470px;
        position: absolute;
        right: -100px;
        z-index: 0;
        filter: blur(40px);
        top: -160px;
        opacity: 0.3;
      }
      .dian2{
        background: #FFA4E0;
        width: 266px;
        height: 470px;
        position: absolute;
        left: -50px;
        z-index: 0;
        filter: blur(40px);
        top: 160px;
        opacity: 0.3;
      }
      .dian3{
        background: radial-gradient(circle, #FFADA9 0%, #CAEDFD 47%, #E6D0F5 84%, #E6CFF4 100%);
        width: 492px;
        height: 766px;
        position: absolute;
        right: -100px;
        left: auto;
        z-index: 0;
        filter: blur(60px);
        bottom: 20%;
        opacity: 0.4;
      }
      .sinfo{
        width: 100%;
        flex-direction: column;
        .imgx{
          width: 560px;
          height: 560px;
          .img{
            width: 100%;
            height: 100%;
          }
        }
        .txtbox{
          margin: 32px auto;
          width: calc(100% - 64px);
          .title{
            font-size: 80px;
            line-height: 88px;
            width: 100%;
            text-align: center;
          }
          .desc{
            width: 100%;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
          }
        }
      }
      .fxm{
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
