export const m = {
  menu:"Home",
  menu0:"Pink Bridge",
  menu1:"Pink MysteryLand",
  menu2:"Pink SWFT",
  menu3:"Pink Master",
  menu4:"Pink NOVA",
  PinkTitle:"Pink Bridge Cross-chain Bridge Based on Fractal Bitcoin",
  desc:"Pink Bridge is an asset cross-chain tool based on Pink Finance, focusing on supporting cross-chain transfers of Tokens/NFTs between the Fractal Bitcoin network and other blockchains. It provides bi-directional bridging, supporting asset transfers between Fractal Bitcoin and other chains.",
  title1:"Pink MysteryLand",
  desc1:"Pink MysteryLand is the first launch platform for NFT series powered by Pink Finance. It supports more NFT projects and creators based on Fractal Bitcoin and provides users with priority access to quality projects to support the NFT ecosystem.",
  title2:"Pink SWFT",
  desc2:"Pink SWFT aims to build an innovative, Fractal Bitcoin-based NFT aggregation trading marketplace to provide users with an enhanced trading experience.",
  title3:"Pink Master",
  desc3:"Pink Master is an NFT liquidity solution based on Fractal Bitcoin.By leveraging advanced cross-chain asset protocols and bond curves, we create sufficient NFT liquidity and provide a more transparent and robust liquidity solution for the NFT market ecosystem.",
  title4:"Pink NOVA",
  desc4:"Pink NOVA will develop an NFT aggregation toolkit based on Fractal Bitcoin, including flexible cross-chain NFT tools, NFT issuance tools, and accompanying AMM-type Swaps. These tools provide NFTs with valuable new features (economic activation, on-chain royalties, leasing mechanisms, time/value/event locks), depreciation protection, and anti-fraud systems. They can be easily applied in GameFi, trading markets, art, the metaverse, and non-collateralized NFT leasing fields.",
 
 
}